import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import RotatingCircleLogo from '../RotatingCircleLogo'
import style from './AboutPrimarySection.module.scss'

const AboutPrimarySection = ({ options = {}, className }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.5,
    triggerOnce: true,
  })
  const { image, title, description } = options

  return (
    <section
      ref={inViewRef}
      className={cs(style.root, className, 'showcase-section', {
        'in-view': inView,
      })}
    >
      <div className="flex flex-wrap items-center h-full -mx-4 md:-mx-6">
        <div className="w-full md:w-1/2 p-4 px-4 md:px-6 xl:px-12 relative">
          <Img
            fixed={image.localFile.childImageSharp.fixed}
            objectFit="contain"
            objectPosition="100% 50%"
            className="block max-w-full w-full primary-appearance"
            style={{ transitionDuration: `1000ms` }}
            backgroundColor="#ebebec"
          />
          <div className={style.rotatingCircleLogo}>
            <RotatingCircleLogo />
          </div>
        </div>
        <div className="w-full md:w-1/2 p-4 md:px-6 xl:px-12">
          <h2
            className="primary-text-section__title max-w-xs primary-appearance"
            style={{ transitionDelay: `150ms` }}
          >
            {title}
          </h2>
          <div
            className="primary-description mt-6 primary-appearance"
            style={{ transitionDelay: `300ms` }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutPrimarySection
