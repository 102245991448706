import React, { useContext } from 'react'
import { animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import style from './AboutSecondarySection.module.scss'
import useScrollX from '../../util/useScrollX'

const trans1 = x => `translate3d(${x * 1.25}px,0,0)`
const trans2 = x => `translate3d(${x * 1.05}px,0,0)`

let inViewOnce = false
const AboutSecondarySection = ({ options = {}, className = '' }) => {
  const { image1, image2, description } = options
  const [{ x }, ref, inView] = useScrollX()

  if (inView) {
    inViewOnce = true
  }

  return (
    <section
      ref={ref}
      className={cs(
        style.root,
        className,
        'primary-text-section overflow-hidden',
        { 'in-view': inViewOnce }
      )}
    >
      <div className={cs(style.container)}>
        <div className={style.images}>
          <animated.div
            style={{ transform: x.interpolate(trans1) }}
            className={style.image1}
          >
            <Img
              fixed={image1.localFile.childImageSharp.fixed}
              objectFit="contain"
              objectPosition="0% 0%"
              className="absolute-fit"
            />
          </animated.div>
          <animated.div
            style={{ transform: x.interpolate(trans2) }}
            className={style.image2}
          >
            <Img
              fixed={image2.localFile.childImageSharp.fixed}
              objectFit="contain"
              objectPosition="100% 100%"
              className="absolute-fit"
            />
          </animated.div>
        </div>
        <div className={style.textContainer}>
          <h2 className={cs(style.title)}>
            <div className="primary-gradient-text-alt primary-appearance">
              Наши
            </div>
            <div
              className="primary-gradient-text primary-appearance"
              style={{ transitionDelay: `150ms` }}
            >
              главные
            </div>
            <div
              className="primary-gradient-text primary-appearance"
              style={{ transitionDelay: `300ms` }}
            >
              ценности
            </div>
          </h2>
          <div
            className={cs(
              style.description,
              'primary-description primary-appearance mt-8'
            )}
            style={{ transitionDelay: `450ms` }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutSecondarySection
