import React from 'react'
import cs from 'classnames'
import style from './ContactForm.module.scss'
import contactFormImage from '../../images/contact-form.jpg'
import ContactForm from './ContactForm'

const ContactFormSection = ({
  overtitle = 'Заходите на чашечку кофе',
  title = 'Связаться с нами',
  className = '',
}) => (
  <section
    className={cs({
      [className]: className,
    })}
  >
    <div className="flex flex-wrap h-full items-center">
      <div className="w-full md:w-3/5">
        <ContactForm overtitle={overtitle} title={title} />
      </div>
      <div
        className={cs(style.image, 'w-2/5 h-full hidden md:block')}
        style={{ backgroundImage: `url('${contactFormImage}')` }}
      />
    </div>
  </section>
)

export default ContactFormSection
