import React from 'react'
import cs from 'classnames'
import style from './AboutCardsSection.module.scss'
import AboutCard from '../AboutCard'

const AboutCardsSection = ({ cards = [], className }) =>
  cards && cards.length ? (
    <section className={cs(style.root, className, 'primary-text-section')}>
      <div className="flex flex-wrap items-stretch h-full -mx-4 xl:-mx-6">
        {cards.map((card, i) => (
          <div key={i} className="w-full md:w-1/3 p-4 xl:px-6">
            <AboutCard {...card} className="h-full" />
          </div>
        ))}
      </div>
    </section>
  ) : null

export default AboutCardsSection
