import React, { useState, useMemo } from 'react'
import MaskedInput from 'react-text-mask'
import hyperid from 'hyperid'
import cs from 'classnames'
import style from './TextField.module.scss'

const instance = hyperid()

const TextField = ({
  mask,
  id,
  label = '',
  type = 'text',
  className = '',
  inputClassName = '',
  textarea = false,
  required = false,
  ...otherProps
}) => {
  const [isToggled, toggle] = useState(false)
  const [isFocused, changeFocus] = useState(false)

  const preparedId = useMemo(() => id || instance(), [id])

  const toggleLabel = (e, newIsFocused) => {
    changeFocus(newIsFocused)
    if (e.currentTarget.value) {
      toggle(true)
    } else {
      toggle(prevToggle => !prevToggle)
    }
  }

  return (
    <div
      className={cs(
        style.root,
        className,
        { [style.toggled]: isToggled },
        { [style.focused]: isFocused }
      )}
    >
      {label ? (
        <label htmlFor={preparedId} className={style.label}>
          {label}
          {required ? '*' : ''}
        </label>
      ) : null}
      {textarea ? (
        <textarea
          id={preparedId}
          onFocus={e => toggleLabel(e, true)}
          onBlur={e => toggleLabel(e, false)}
          className={cs(inputClassName, style.input)}
          {...otherProps}
        />
      ) : mask ? (
        <MaskedInput
          id={preparedId}
          type={type}
          mask={mask}
          onFocus={e => toggleLabel(e, true)}
          onBlur={e => toggleLabel(e, false)}
          className={cs(inputClassName, style.input)}
          {...otherProps}
        />
      ) : (
        <input
          id={preparedId}
          type={type}
          onFocus={e => toggleLabel(e, true)}
          onBlur={e => toggleLabel(e, false)}
          className={cs(inputClassName, style.input)}
          {...otherProps}
        />
      )}
    </div>
  )
}

export default TextField
