import React from 'react'
import cs from 'classnames'
import { useInView } from 'react-intersection-observer'
import style from './AboutCard.module.scss'

const AboutCard = ({
  number,
  title,
  description,
  children,
  className = '',
  ...props
}) => {
  const [inViewRef, inView] = useInView({
    triggerOnce: true,
  })
  return (
    <div
      ref={inViewRef}
      className={cs(style.root, className, { 'in-view': inView })}
      {...props}
    >
      {number && (
        <div className={cs(style.number, 'primary-appearance')}>{number}</div>
      )}
      {title && (
        <h3
          className={cs(style.title, 'mt-6 mb-8 primary-appearance')}
          style={{ transitionDelay: '150ms' }}
        >
          {title}
        </h3>
      )}
      {description && (
        <div
          className={cs(
            style.description,
            'mt-8 primary-description primary-appearance'
          )}
          style={{ transitionDelay: '300ms' }}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  )
}

export default AboutCard
