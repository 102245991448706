import React, { useContext } from 'react'
import { animated } from 'react-spring'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import style from './AboutStartSection.module.scss'
import GlobalContext from '../GlobalContext'
import useScrollX from '../../util/useScrollX'

const trans1 = x => `translate3d(${x * 1.25}px,0,0)`
const trans2 = x => `translate3d(${-x * 1.25}px,0,0)`

let inViewOnce = false
const AboutStartSection = ({ options = {}, className = '' }) => {
  const { image1, image2, description } = options
  const { isLoaded } = useContext(GlobalContext)
  const [{ x }, ref, inView] = useScrollX()

  if (inView) {
    inViewOnce = true
  }

  return (
    <section
      ref={ref}
      className={cs(
        style.root,
        className,
        'flex flex-wrap lg:flex-no-wrap items-center primary-logo-padding-offset overflow-hidden',
        { 'in-view': isLoaded && inViewOnce }
      )}
    >
      <div className={style.images}>
        <animated.div
          style={{ transform: x.interpolate(trans1) }}
          className={style.image1}
        >
          <Img
            fixed={image1.localFile.childImageSharp.fixed}
            objectFit="contain"
            objectPosition="50% 100%"
            className="absolute-fit"
          />
        </animated.div>
        <animated.div
          style={{ transform: x.interpolate(trans2) }}
          className={style.image2}
        >
          <Img
            fixed={image2.localFile.childImageSharp.fixed}
            objectFit="contain"
            objectPosition="50% 0%"
            className="absolute-fit"
          />
        </animated.div>
      </div>
      <div className="flex-auto overflow-visible">
        <div
          className={cs(
            style.textContainer,
            'primary-text-section xl:max-w-xl'
          )}
        >
          <h1 className={cs(style.title)}>
            <div className="primary-gradient-text-alt primary-appearance">
              Синоним
            </div>
            <div
              className="primary-gradient-text primary-appearance"
              style={{ transitionDelay: `150ms` }}
            >
              высочайшего
            </div>
            <div
              className="primary-gradient-text primary-appearance"
              style={{ transitionDelay: `300ms` }}
            >
              качества
            </div>
          </h1>
          <div
            className={cs(
              style.description,
              'primary-description primary-appearance'
            )}
            style={{ transitionDelay: `450ms` }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </section>
  )
}

export default AboutStartSection
