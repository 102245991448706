import React, { useState, useMemo } from 'react'
import cs from 'classnames'
import hyperid from 'hyperid'
import style from './Checkbox.module.scss'

const instance = hyperid()

const Checkbox = ({
  type,
  text = '',
  id = '',
  onChange = f => f,
  checked = false,
  ...props
}) => {
  const [isChecked, toggle] = useState(checked)

  const preparedId = useMemo(() => id || instance(), [id])

  function onChangeComposed() {
    toggle(oldIsChecked => !oldIsChecked)
    onChange.apply(this, arguments)
  }

  return (
    <label
      htmlFor={preparedId}
      className={cs(style.root, { [style.checked]: isChecked })}
    >
      <input
        type="checkbox"
        className={style.input}
        id={preparedId}
        onChange={onChangeComposed}
        checked={isChecked}
        {...props}
      />
      <div className={style.checkbox} />
      <div dangerouslySetInnerHTML={{ __html: text }} className={style.text} />
    </label>
  )
}

export default Checkbox
