import React from 'react'
import cs from 'classnames'
import Link from '../Link'
import style from './ShowroomSection.module.scss'

const ShowroomSection = ({ options = {}, className }) => {
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  // })

  const imageSrc = options.image
    ? options.image.localFile.childImageSharp.fixed.src
    : ''
  return (
    <section
      className={cs({
        [style.root]: true,
        [className]: className,
        'showcase-section': true,
      })}
    >
      <div className="h-full flex items-stretch flex-wrap">
        <div className="w-auto">
          <div className="showcase-section__big-text">
            <div className="showcase-section__big-text-inner">
              <span>{options.left_text}</span>
            </div>
          </div>
        </div>
        <div className="md:flex-auto w-full md:w-auto relative flex flex-col">
          <div className={style.textContainer}>
            <h2 className={style.title}>{options.title}</h2>
            {options.link && (
              <Link
                className={`${style.watchLink} primary-link`}
                to={options.link}
              >
                {options.link_text}
              </Link>
            )}
          </div>
          <div
            className={style.videoContainer}
            style={{ backgroundImage: `url(${imageSrc})` }}
          >
            <button type="button" className={style.playButton}>
              <i
                className={cs(
                  options.icon ? options.icon : 'icon-phone',
                  'text-2xl'
                )}
              />
            </button>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ShowroomSection
