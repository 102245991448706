import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import HorizontalScrolling from '../../components/HorizontalScrolling'
import AboutStartSection from '../../components/AboutStartSection'
import AboutPrimarySection from '../../components/AboutPrimarySection'
import AboutVideoSection from '../../components/AboutVideoSection'
import AboutSecondarySection from '../../components/AboutSecondarySection'
import AboutCardsSection from '../../components/AboutCardsSection'
import ShowroomSection from '../../components/ShowroomSection'
import ContactForm from '../../components/ContactForm'

const AboutPage = ({ data }) => {
  const {
    wordpressPage: {
      acf: {
        start_section,
        primary_section,
        video_section,
        secondary_section,
        cards_section,
        showroom_section,
      },
    },
  } = data

  return (
    <HorizontalScrolling>
      <AboutStartSection
        options={start_section}
        className="screen-width-section"
      />
      <AboutPrimarySection
        options={primary_section}
        className="screen-width-section"
      />
      <AboutVideoSection
        options={video_section}
        className="screen-width-section"
      />
      <AboutSecondarySection
        options={secondary_section}
        className="screen-width-section"
      />
      <AboutCardsSection
        cards={cards_section}
        className="screen-width-section"
      />
      <ShowroomSection
        options={showroom_section}
        className="screen-width-section"
      />
      <ContactForm className="screen-width-section" />
    </HorizontalScrolling>
  )
}
AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default AboutPage

export const query = graphql`
  query($id: String!) {
    wordpressPage(id: { eq: $id }) {
      acf {
        start_section {
          image1 {
            localFile {
              childImageSharp {
                fixed(width: 300, height: 409, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                fixed(width: 374, height: 602, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          description
        }
        primary_section {
          image {
            localFile {
              childImageSharp {
                fixed(width: 575, height: 307, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          title
          description
        }
        video_section {
          image {
            localFile {
              childImageSharp {
                fixed(width: 1920, height: 1080, quality: 95) {
                  src
                }
              }
            }
          }
          video
        }
        secondary_section {
          image1 {
            localFile {
              childImageSharp {
                fixed(width: 485, height: 293, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          image2 {
            localFile {
              childImageSharp {
                fixed(width: 281, height: 390, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
          description
        }
        cards_section {
          number
          title
          description
        }
        showroom_section {
          left_text
          title
          link
          link_text
          image {
            localFile {
              childImageSharp {
                fixed(width: 920, height: 500, quality: 95) {
                  src
                }
              }
            }
          }
        }
      }
    }
  }
`
