import { useState, useContext, useEffect, useCallback, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSpring } from 'react-spring'
import ScrollContext from '../components/HorizontalScrolling/ScrollContext'
import GlobalContext from '../components/GlobalContext'

export default () => {
  const sizeRef = useRef(null)
  const [inViewRef, inView] = useInView()
  const ref = useCallback(
    node => {
      inViewRef(node)
      sizeRef.current = node
    },
    [sizeRef, inViewRef]
  )

  const [centerX, setCenterX] = useState(0)
  const { eventEmitter } = useContext(ScrollContext)
  const { isLargeScreen } = useContext(GlobalContext)
  const [props, set] = useSpring(() => ({
    x: 0,
    immediate: true,
  }))

  useEffect(() => {
    if (isLargeScreen) {
      setCenterX(sizeRef.current.getBoundingClientRect().left)
    }
  }, [isLargeScreen])

  const setXIfInView = x => {
    // console.log(x)
    if (inView) {
      set({ x: (x - centerX) / 30 })
    }
  }

  useEffect(() => {
    if (isLargeScreen) {
      eventEmitter.on('scroll', setXIfInView)
    } else {
      eventEmitter.off('scroll', setXIfInView)
    }
    return () => eventEmitter.off('scroll', setXIfInView)
  }, [inView, isLargeScreen])

  useEffect(() => {
    set({ x: isLargeScreen ? centerX / 30 : 0 })
  }, [isLargeScreen])

  return [props, ref, inView]
}
