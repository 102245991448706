import React from 'react'
import cs from 'classnames'
import style from './AboutVideoSection.module.scss'

const AboutVideoSection = ({ options = {}, className }) => {
  const { image, video } = options

  const imageSrc =
    image && image.localFile ? image.localFile.childImageSharp.fixed.src : ''

  return (
    <section
      className={cs(style.root, className)}
      style={{ backgroundImage: `url('${imageSrc}')` }}
    >
      <button type="button" className={style.playButton}>
        <i className="icon-play-button" />
      </button>
    </section>
  )
}

export default AboutVideoSection
