import React, { useContext } from 'react'
import { useInView } from 'react-intersection-observer'
import cs from 'classnames'
import phoneMask from '../../util/phoneMask'
import TextField from '../TextField'
import Checkbox from '../Checkbox'
import style from './ContactForm.module.scss'
import GlobalContext from '../GlobalContext'

const ContactForm = ({ overtitle = '', title = '', action = '' }) => {
  const [inViewRef, inView] = useInView({
    threshold: 0.25,
    triggerOnce: true,
  })
  const { isLoaded } = useContext(GlobalContext)

  const onSubmit = e => {
    e.preventDefault()
  }

  return (
    <form
      ref={inViewRef}
      action={action}
      onSubmit={onSubmit}
      className={cs('max-w-xl primary-text-section mx-auto', {
        'in-view': inView && isLoaded,
      })}
    >
      <div
        className={cs(
          style.overtitle,
          'primary-appearance',
          'primary-text-section__overtitle'
        )}
      >
        {overtitle}
      </div>
      <h2
        className={cs(
          style.title,
          'primary-appearance',
          'primary-text-section__title'
        )}
        style={{ transitionDelay: '150ms' }}
      >
        {title}
      </h2>
      <div
        className="mt-8 primary-appearance"
        style={{ transitionDelay: '250ms' }}
      >
        <TextField
          type="tel"
          name="user_phone"
          label="Контактный телефон"
          mask={phoneMask}
          placeholder="8 (999) 999-99-99"
          required
        />
      </div>
      <div
        className="mt-4 primary-appearance"
        style={{ transitionDelay: '350ms' }}
      >
        <TextField label="Как к вам обращаться?" required name="user_name" />
      </div>
      <div
        className="mt-4 primary-appearance"
        style={{ transitionDelay: '450ms' }}
      >
        <TextField
          textarea
          rows="5"
          label="Ваше сообщение"
          name="user_message"
        />
      </div>
      <div
        className="mt-6 -mx-4 flex flex-wrap primary-appearance"
        style={{ transitionDelay: '550ms' }}
      >
        <div className="px-4 flex-1">
          <Checkbox
            name="agreement"
            required
            text="Даю согласию на обработку персональных данных"
          />
        </div>
        <div className="px-4 w-full sm:w-auto text-right">
          <button type="submit" className="btn btn--primary btn--large">
            Отправить
          </button>
        </div>
      </div>
    </form>
  )
}

export default ContactForm
